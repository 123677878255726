const {calculateSeasonStart, calculateSeasonEnd} = require("./seasonInfo");
const _ = require('lodash');
const {roundToForDecimals} = require("./numberRounding");

module.exports = {
    findFieldYearInfo: (field, yearValue) => {
        if (typeof field === 'string' || field instanceof String) {
            return undefined
        }
        const year = parseInt(yearValue);
        let correctYearInfo = _.find(field.yearInfo, yearInfo => yearInfo.year === year);
        if (correctYearInfo) {
            return correctYearInfo
        } else {
            const correctSeasonStartDate = calculateSeasonStart(field, year);
            let orderedFieldInfo = _.sortBy(field.yearInfo, [info => info.year]); // Sorted 2000, 2001, 2002
            let previousYearInfo = _.filter(orderedFieldInfo, info => { return info.year <= year; });

            let yearInfoToUse;
            if (previousYearInfo.length > 0) {
                yearInfoToUse = previousYearInfo[previousYearInfo.length-1]; // Highest year from previously defined years
            } else {
                yearInfoToUse = orderedFieldInfo[0] // Lowest year (closest to the requested year)
            }
            if (typeof yearInfoToUse.toObject === "function") {
                yearInfoToUse = yearInfoToUse.toObject();
            }
            const fieldDataToCarryOver = _.pick(yearInfoToUse, ["name", "area", "priaCode", "slopedArea", "externalId"]);
            return _.merge({}, fieldDataToCarryOver, {year: year, seasonStart: correctSeasonStartDate});
        }
    },
    findFieldYearInfoForDate: (field, dateValue) => {
        if (typeof field === 'string' || field instanceof String) {
            return undefined
        }
        const date = new Date(dateValue);
        const fullYear = date.getFullYear();
        const seasonStart = calculateSeasonStart(field, fullYear);
        const seasonEnd = calculateSeasonEnd(field, fullYear);
        if (date >= seasonStart && date < seasonEnd) {
            return module.exports.findFieldYearInfo(field, fullYear);
        } else if (date >= seasonEnd) {
            return module.exports.findFieldYearInfo(field, fullYear+1);
        } else {
            return module.exports.findFieldYearInfo(field, fullYear-1);
        }
    },
    getCurrentUserTotalActiveArea: (fields, year) => {
        const activeFields = _.filter(fields, (field) => field.active);
        const allFieldsArea = _.map(activeFields, (field) => {
            const yearFieldInfo = module.exports.findFieldYearInfo(field, year);
            return yearFieldInfo.area;
        });
        return roundToForDecimals(_.sum(allFieldsArea));
    }
};
