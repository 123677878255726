import {http} from "../../config/http.js"

import _ from "lodash";
import {calculateActiveYears} from "../../../../dist/common/activeYearsCalculator";
import {findCropColor} from "@/mixins/mixins";
import {getCurrentUserTotalActiveArea} from "../../../../backend/common/fieldYearInfo";

const state = {
    fields: [],
    onlineFieldMapData: null,
    tempOrder: null,
    fieldSearchPhrase: null,
};

const mutations = {
    SET_FIELDS(state, fields) {
        state.fields = fields
    },
    ADD_FIELD(state, newField) {
        state.fields.push(newField)
    },
    MODIFIED_FIELD(state, changedField) {
        const index = _.findIndex(state.fields, _.pick(changedField, "_id"));
        state.fields.splice(index, 1, changedField);
        // Object.assign(state.fields[index], changedField);
    },
    REMOVE_FIELD(state, fieldToDelete) {
        state.fields = _.filter(state.fields, (field) => {
            return field._id !== fieldToDelete._id;
        })
    },
    REORDER_FIELDS(state, {oldIndex, newIndex}) {
        if (state.fields && state.fields.length > 0) {
            const movedItem = state.fields.splice(oldIndex, 1)[0];
            state.fields.splice(newIndex, 0, movedItem)
        }
    },
    SAVE_TEMP_ORDER(state, tempOrder) {
        state.tempOrder = tempOrder
    },
    SET_FIELD_MAP_DATA(state, onlineFieldMapData) {
        state.onlineFieldMapData = onlineFieldMapData
    },
    UPDATE_FIELD_SEARCH_PHRASE(state, newFieldSearchPhrase) {
        state.fieldSearchPhrase = newFieldSearchPhrase
    },
};

const actions = {
    loadAllFields({commit}) {
        return http().get('/fields')
            .then(r => r.data.data)
            .then(fields => {
                commit('SET_FIELDS', fields);
            })
    },
    addField({commit}, newFieldData) {
        return http().post('/fields', newFieldData)
            .then(r => r.data.data)
            .then(response => {
                commit('ADD_FIELD', response)
            })
    },
    editField({commit}, modifiedData) {
        return http().put('/fields/' + modifiedData._id, modifiedData)
            .then(r => r.data.data)
            .then(response => {
                commit('MODIFIED_FIELD', response)
            })
    },
    updateFieldSearchPhrase({commit}, newFieldSearchPhrase) {
        commit('UPDATE_FIELD_SEARCH_PHRASE', newFieldSearchPhrase)
    },
    loadPriaMapData({commit}, fieldExternalId) {
        if (fieldExternalId !== null && fieldExternalId !== undefined) {
            return http().get('/external-communication/pria/field/' + fieldExternalId)
                .then(r => r.data.data)
                .then(response => {
                    if (response) {
                        commit('SET_FIELD_MAP_DATA', response)
                    } else {
                        commit('SET_FIELD_MAP_DATA', null);
                    }
                })
        }
    },
    loadFieldWithMapData({commit}, fieldId) {
        return http().get('/fields/' + fieldId)
            .then(r => r.data.data)
            .then(response => {
                if (response) {
                    commit('MODIFIED_FIELD', response)
                }
                return response;
            }).catch(() => {})
    },
    clearFieldMapData({commit}) {
        commit('SET_FIELD_MAP_DATA', null);
    },
    removeField({commit}, field) {
        return http().delete('/fields/' + field._id)
            .then(() => {
                commit('REMOVE_FIELD', field)
            })
    },
    removeAllFields({commit}) {
        return http().delete('/fields/all')
            .then(() => {
                commit('SET_FIELDS', [])
            })
    },
    toggleFieldActiveStatus({commit}, field) {
        return http().put('/fields/' + field._id + '/togglestatus')
            .then(r => r.data.data)
            .then(response => {
                commit('MODIFIED_FIELD', response)
            })
    },
    updatePredictedYield({commit}, data) {
        return http().put('/fields/' +data.year + '/predicted-yield', data)
            .then(r => r.data.data)
            .then(fields => {
                commit('SET_FIELDS', fields);
            })
    },
    reorderFields({commit}, {newOrdering, oldIndex, newIndex}) {
        commit('REORDER_FIELDS', {oldIndex, newIndex});
        http().put('/fields/ordering', newOrdering);
    },
    saveTemporaryReorder({commit}, tempOrder) {
        commit('SAVE_TEMP_ORDER', tempOrder);
    },
    // eslint-disable-next-line no-unused-vars
    saveSeasonStartForAllFields({commit}, seasonData) {
        return http().put('/fields/' + seasonData.year + '/seasons', {seasonStart: seasonData.seasonStart})
            .then(r => r.data.data)
    },
    editCropRotation({dispatch}, update) {
        return http().post(`/fields/${update.fieldId}/crops-rotation`, update)
            .then(r => r.data)
            .then(() => {
                dispatch('loadAllFields');
            })
    },
};

const getters = {
    fields: state => {
        return _.filter(state.fields, (field) => field.active);
    },
    allFields: state => {
        return state.fields
    },
    cropColors: state => {
        const allCrops = _.flatMap(state.fields, field => {
            return _.map(field.yearInfo, yearInfo => yearInfo.mainCrop?.crop)
        }).filter(crop => crop !== undefined && crop !== null);
        return findCropColor.methods.findCropMapColors(allCrops);
    },
    hasAnyFields: state => {
        return state.fields && state.fields.length > 0;
    },
    getTempOrder: state => {
        return state.tempOrder
    },
    getFieldsFromYear: state => year => {
        return _.filter(state.fields, (field) => _.find(field.yearInfo, info => {
            return info.year === year
        }))
    },
    getFieldYears: (state) => {
        let fieldYears = _.cloneDeep(calculateActiveYears(state.fields));
        return fieldYears.sort().reverse();
    },
    field: state => id => {
        return state.fields.find(field => field._id === id);
    },
    getFieldSearchPhrase: state => {
        return state.fieldSearchPhrase;
    },
    fieldTotalArea: (state) => year => {
        let areas = _.map(state.fields, (field) => {
            let info = _.find(field.yearInfo, info => {return info.year === year});
            if (info) {
                return info.area;
            }
            return 0;
        });
        return (Math.round(_.sum(areas) * 100) / 100);
    },
    activeFieldTotalArea: (state) => year => {
        return getCurrentUserTotalActiveArea(state.fields, year);
    },
    linkedUsersActiveFieldTotalArea: (state, getters) => {
        return getters.getTotalActiveArea;
    },
    getOnlineFieldMapData: (state) => {
        return state.onlineFieldMapData;
    },
    getLastUpdatedFieldDate: (state) => {
        if (state.fields) {
            return _.max(_.map(state.fields, field => new Date(field.updatedAt)));
        } else {
            return undefined;
        }
    },
    getYearsWithActivity: (state, getters) => {
        let activeYearsWithCurrentYear = getters.getFieldYears;
        if (getters.getCurrentFullYear) {
            activeYearsWithCurrentYear.push(getters.getCurrentFullYear)
        }
        return (_.uniq(activeYearsWithCurrentYear)).sort().reverse();
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}