import Vue from 'vue'
import Router from 'vue-router'

import main from '../viewes/main.vue'
import warnings from '../viewes/warnings.vue'
import login from '../viewes/login.vue'
import demo from '../viewes/demo.vue'
import Polluraamat from '../Polluraamat'
import store from '../store/store'
import {findFieldYearInfo} from "../../../dist/common/fieldYearInfo.js"

Vue.use(Router);
let entryUrl = null;

const ifNotAuthenticated = async (to, from, next) => {
    await store.dispatch('loadUser').then(() => {
        if (!store.getters.isAuthenticated) {
            next();
        } else {
            next({name:'appRoot'})
        }
    });
};

const ifAuthenticated = async (to, from, next) => {
    if (store.getters.isAuthenticated) {
        if (entryUrl) {
            const url = entryUrl;
            entryUrl = null;
            return next(url);
        } else {
            return next();
        }
    }
    await store.dispatch('loadUser');

    if (store.getters.isAuthenticated) {
        next()
    } else {
        entryUrl = to.fullPath; // store entry url before redirect
        next('/sisene')
    }
};

const router = new Router({
    root: '/',
    routes:
        [
            {
                path: '/sisene',
                name: 'login',
                component: login,
                beforeEnter: ifNotAuthenticated
            },
            {
                path: '/registreeru',
                name: 'registration',
                component: () => import('../viewes/registration.vue'),
                beforeEnter: ifNotAuthenticated
            },
            {
                path: '/unustasinparooli',
                name: 'resetPassword',
                component: () => import('../viewes/resetPassword.vue'),
                beforeEnter: ifNotAuthenticated
            },
            {
                path: '/uusparool',
                name: 'newPassword',
                component: () => import('../viewes/setNewPassword.vue'),
                beforeEnter: ifNotAuthenticated
            },
            {
                path: '/',
                name: 'welcome',
                component: () => import('../viewes/welcome.vue'),
                beforeEnter: ifNotAuthenticated
            },
            {
                path: '/demo',
                name: 'demo',
                component: demo,
                beforeEnter: ifNotAuthenticated
            },
            {
                path: '/',
                component: Polluraamat,
                beforeEnter: ifAuthenticated,
                children: [
                    {
                        path: '/lisa',
                        name: 'appRoot',
                        component: main,
                        meta: {
                            title: () => [{text: 'Lisa töö'}]
                        },
                    },
                    {
                        path: '/pollud/:year',
                        name: 'pollud',
                        component: () => import('../fields/allFields.vue'),
                        props: true,
                        meta: {
                            title: () => [{text: 'Põllud'}]
                        },
                    },
                    {
                        path: '/tood/:year',
                        name: 'jobs-management',
                        component: () => import('../jobsoverview/jobsManagement.vue'),
                        props: true,
                        meta: {
                            title: () => [{text: 'Tööde haldus'}]
                        },
                    },
                    {
                        path: '/pria/:year',
                        name: 'pria-sync',
                        component: () => import('../pria/jobSyncOverview.vue'),
                        props: true,
                        meta: {
                            title: () => [{text: 'PRIA andmevahetus'}]
                        },
                    },
                    {
                        path: '/pollud/:year/:id',
                        name: 'jobEvents',
                        component: () => import('../field/field.vue'),
                        meta: {
                            title: (routeParams) => {
                                if (store.getters.field(routeParams.id)) {
                                    return [
                                        {text: "Põllud", to: "/pollud/" + routeParams.year},
                                        {text: findFieldYearInfo(store.getters.field(routeParams.id), routeParams.year).name}
                                    ]
                                } else {
                                    return [{text: "Põllud", to: "/pollud/" + routeParams.year}]
                                }
                            }
                        }
                    },
                    {
                        path: '/seemned',
                        name: 'seeds',
                        component: () => import('../management/seeds.vue'),
                        meta: {
                            title: () => [{text: 'Seemned'}]
                        },
                    },
                    {
                        path: '/vaetised',
                        name: 'fertilizers',
                        component: () => import('../management/fertilizers.vue'),
                        meta: {
                            title: () => [{text: 'Väetised'}]
                        },
                    },
                    {
                        path: '/taimekaitsevahendid',
                        name: 'chemicals',
                        component: () => import('../management/chemicals.vue'),
                        meta: {
                            title: () => [{text: 'Taimekaitsevahendid'}]
                        },
                    },
                    {
                        path: '/tarvikud',
                        name: 'requisites',
                        component: () => import('../management/requisites.vue'),
                        meta: {
                            title: () => [{text: 'Teised tarvikud'}]
                        },
                    },
                    {
                        path: '/tootajad',
                        name: 'workers',
                        component: () => import('../management/workers.vue'),
                        meta: {
                            title: () => [{text: 'Töötajad'}]
                        },
                    },
                    {
                        path: '/tookategooriate-haldus',
                        name: 'categories',
                        component: () => import('../jobcategories/settings.vue'),
                        meta: {
                                title: () => [{text: 'Töötüübid'}]
                        },
                    },
                    {
                        path: '/seaded',
                        name: 'settings',
                        component: () => import('../settings/settings.vue'),
                        meta: {
                            title: () => [{text: 'Seaded'}]
                        },
                    },
                    {
                        path: '/ladu/',
                        name: 'sockOverview',
                        component: () => import("@/stock/stockOverviewContainer.vue"),
                        meta: {
                            title: () => [{text: 'Ladu'}]
                        },
                    },
                    {
                        path: '/tooaeg/:year',
                        name: 'workTime',
                        component: () => import('../worktime/workTimeOverview.vue'),
                        meta: {
                            title: () => [{text: 'Tööaeg'}]
                        },
                    },
                    {
                        path: '/vaetamiseplaan/:year',
                        name: 'fertilizationPlan',
                        component: () => import('../fertilizationplan/fertilizationPlan.vue'),
                        meta: {
                            title: () => [{text: 'Väetamiseplaan'}]
                        },
                    },
                    {
                        path: '/viljavaheldusplaan',
                        name: 'cropRotation',
                        component: () => import('../cropsRotation/cropsRotation.vue'),
                        meta: {
                            title: () => [{text: 'Viljavaheldusplaan'}]
                        },
                    },
                    {
                        path: '/tarvikuteplaan/:year',
                        name: 'requisitePlan',
                        component: () => import('../requisiteplan/requisitePlan.vue'),
                        meta: {
                            title: () => [{text: 'Kuluplaan'}]
                        },
                    },
                    {
                        path: '/saagikuseplaneerimine/:year',
                        name: 'yieldPlan',
                        component: () => import('../predictedYield/predictedYield.vue'),
                        meta: {
                            title: () => [{text: 'Saagikuse planeerimine'}]
                        },
                    },
                    {
                        path: '/konto',
                        name: 'account',
                        component: () => import('../account/account.vue'),
                        meta: {
                            title: () => [{text: 'Teie kasutajakonto'}]
                        },
                    },
                    {
                        path: '/ylevaade',
                        name: 'adminStatistics',
                        component: () => import('../viewes/admin.vue'),
                        meta: {
                            title: () => [{text: 'Ülevaade'}]
                        },
                    },
                    {
                        path: '/tellimused',
                        name: 'orders',
                        component: () => import('../admin/orders.vue'),
                        meta: {
                            title: () => [{text: 'Tellimused'}]
                        },
                    },
                    {
                        path: '/abi',
                        name: 'help',
                        component: () => import('../help/help.vue'),
                        meta: {
                            title: () => [{text: 'Abiinfo'}]
                        },
                    },
                    {
                        path: '/abivahendid',
                        name: 'helpmaterials',
                        component: () => import('../helpmaterials/helpmaterials.vue'),
                        meta: {
                            title: () => [{text: 'Abivahendid'}]
                        },
                    },
                    {
                        path: '/toode-kokkuvote/:year', // TODO: DEPRECATED
                        name: 'jobsOverview',
                        component: () => import('../jobsoverview/jobsoverview.vue'),
                        meta: {
                            title: () => [{text: 'Tööde kokkuvõte'}]
                        },
                    },
                    {
                        path: '/saagikus/:year',
                        name: 'yieldOverview',
                        component: () => import('../yieldoverview/yieldoverview'),
                        meta: {
                            title: () => [{text: 'Saagikus'}]
                        },
                    },
                    {
                        path: '/hoiatused',
                        name: 'warnings',
                        component: warnings,
                        meta: {
                            title: () => [{text: 'Hoiatused'}]
                        },
                    },
                    {
                        path: '/uuendused',
                        name: 'changelog',
                        component: () => import('../changelog/changelog.vue'),
                        meta: {
                            title: () => [{text: 'Uuenduste ajalugu'}]
                        },
                    },
                ]
            }
        ]
});

export default router