const _ = require("lodash");

function calculateSeasonStart(field, year) {
    let currentYearInfo = _.find(field.yearInfo, yearInfo => yearInfo.year === year);

    if (currentYearInfo) {
        return new Date(currentYearInfo.seasonStart);
    } else {
        return new Date(Date.UTC(year, 0, 1));
    }
}

module.exports = {
    calculateSeasonStart: (field, year) => {
        return calculateSeasonStart(field, parseInt(year));
    },
    calculateSeasonEnd: (field, year) => {
        return calculateSeasonStart(field, parseInt(year) + 1);
    }
};
